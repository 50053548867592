@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@import "tailwindcss/base";

/**
   * This injects Tailwind's component classes and any component classes
   * registered by plugins.
   */
@import "tailwindcss/components";

/**
   * This injects Tailwind's utility classes and any utility classes registered
   * by plugins.
   */
@import "tailwindcss/utilities";

/**
   * This injects Tailwind's utility classes and any utility classes registered
   * by plugins.
   */
@import "tailwindcss/variants";

/**
   * Lightweight, robust, elegant syntax highlighting.
   * https://github.com/PrismJS/prism
   */
@import "./prism.css";

/**
   * Montserrat
   * https://fonts.google.com/selection/embed
   */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/**
   * Afronaut
   * https://fonts.adobe.com/fonts/afronaut
   */
::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #4f46e5;
}

::selection {
  color: white;
  background: #4f46e5;
}

.max-w-8xl {
  max-width: 90rem;
}

.pixel-wrapper {
  width: 45px;
  height: 45px;
  margin: auto;
}

.pixel {
  width: 15px;
  height: 15px;
  display: block;
  float: left;
  transition: all 1000ms ease;
}

svg #wheel {
  animation: scroll ease 1.5s infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(100px);
  }
}

.word-animation {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding-right: 5px;
  margin-right: -5px;
}
.word-animation {
  background-color: #101d24;
  background-image: -webkit-image-set(url("../public/assets/img/text_background@1x.webp") 1x, url("../public/assets/img/text_background.webp") 2x);
  background-image: image-set("../public/assets/img/text_background@1x.webp" 1x, "../public/assets/img/text_background.webp" 2x);
  background-repeat: repeat;
  background-size: 1024px;
  -webkit-animation: 71s diagonal-flow infinite linear, 23s hue-rotate infinite linear;
  animation: 71s diagonal-flow infinite linear, 23s hue-rotate infinite linear;
}

@-webkit-keyframes diagonal-flow {
  0% {
    background-position: 100px 100px;
  }
  100% {
    background-position: -5020px 4196px;
  }
}
@keyframes diagonal-flow {
  0% {
    background-position: 100px 100px;
  }
  100% {
    background-position: -5020px 4196px;
  }
}
@-webkit-keyframes hue-rotate {
  0% {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  50% {
    -webkit-filter: hue-rotate(-60deg);
    filter: hue-rotate(-60deg);
  }
  100% {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
}
@keyframes hue-rotate {
  0% {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  50% {
    -webkit-filter: hue-rotate(-60deg);
    filter: hue-rotate(-60deg);
  }
  100% {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
}

.d1::before,
.d2::before,
.d3::before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  margin-top: -2.25px;
}
.d1 {
  font-size: 54px;
  letter-spacing: -0.05em;
  line-height: 112%;
}
@media (min-width: 436px) {
  .d1 {
    font-size: 60px;
  }
}
@media (min-width: 1024px) {
  .d1 {
    font-size: 69px;
  }
}
@media (min-width: 1240px) {
  .d1 {
    font-size: 91.5px;
  }
}
.d2 {
  font-size: 51px;
  letter-spacing: -0.05em;
  line-height: 110%;
}
@media (min-width: 436px) {
  .d2 {
    font-size: 57px;
  }
}
@media (min-width: 1024px) {
  .d2 {
    font-size: 63px;
  }
}
@media (min-width: 1240px) {
  .d2 {
    font-size: 69px;
  }
}

/**
   * On-Scroll Image Layout Animations
   * An exploration of different scroll based layout switch animations.
   */

/* Page Loader */

:root {
  --color-text: #fff;
  --color-bg: #131417;
  --color-link: #aaa;
  --color-link-hover: #fff;
  --color-label: #adadad;
  --color-text-alt: #575757;
  --color-caption: #fff;
}

.js .loading::before,
.js .loading::after {
  content: "";
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

.unbutton {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.unbutton:focus {
  outline: none;
}

.project {
  display: grid;
  grid-column-gap: 7vw;
  grid-row-gap: 0.5rem;
  font-size: 1.125rem;
}

.project--intro {
  grid-template-columns: 1fr;
  grid-template-areas:
    "label-name"
    "name"
    "label-date"
    "date"
    "title"
    "label-mission"
    "mission";
}

.project--details {
  grid-template-areas: "label-default" "paragraph";
  grid-template-columns: 1fr;
}

.project--left {
  justify-content: start;
}

.project--right {
  justify-content: end;
}

.project--details p {
  grid-area: paragraph;
  max-width: 400px;
}

.project__label--default {
  grid-area: label-default;
}

.project p {
  line-height: 1.4;
  margin: 0;
  color: var(--color-text-alt);
}

.project__label {
  color: var(--color-label);
}

.project__label--name {
  grid-area: label-name;
}

.project__label--date {
  grid-area: label-date;
}

.project__label--mission {
  grid-area: label-mission;
}

.project__name {
  grid-area: name;
}

.project__date {
  grid-area: date;
}

.project__mission {
  grid-area: mission;
  line-height: 1.4;
}

.project__mission p:first-child {
  grid-area: p1;
}

.project__mission p:child {
  grid-area: p2;
}

.project__mission p {
  color: var(--color-text-alt);
}

/* .project__title {
   grid-area: title;
   font-size: clamp(2rem, 13vw, 8rem);
   font-weight: 400;
   margin: 10vh 0;
   line-height: 1;
 } */

.project__title {
  grid-area: title;
  margin: 10vh 0;
}

.project__heading {
  color: var(--color-label);
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

.gallery-wrap {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20vh auto;
  overflow: hidden;
}

.gallery-wrap--auto {
  height: auto;
}

.gallery-wrap--large {
  height: 110vh;
}

.gallery-wrap--dense {
  margin: 0;
}

.gallery {
  position: relative;
  width: 100%;
  height: 100%;
  flex: none;
}

.gallery--breakout {
  width: min-content;
}

.gallery__item {
  background-position: 50% 50%;
  background-size: cover;
  flex: none;
  border-radius: 6px;
  position: relative;
  filter: brightness(1);
}

.gallery__item-cut {
  overflow: hidden;
  display: grid;
  place-items: center;
}

.gallery__item-inner {
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.caption {
  font-size: clamp(1rem, 3vw, 1.75rem);
  z-index: 101;
  color: var(--color-caption);
  font-weight: 400;
}

.gallery--row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  --size-factor: 1.25;
  --item-height: 20vh;
}

.gallery--row .gallery__item {
  width: auto;
  aspect-ratio: 2 / 3;
}

.gallery--row .gallery__item--s {
  height: var(--item-height);
}

.gallery--row .gallery__item--m {
  height: calc(var(--size-factor) * var(--item-height));
}

.gallery--row .gallery__item--l {
  height: calc(var(--size-factor) * 2 * var(--item-height));
}

.gallery--row .gallery__item--xl {
  z-index: 100;
  height: calc(var(--size-factor) * 3 * var(--item-height));
}

.gallery--switch.gallery--row .gallery__item--m,
.gallery--switch.gallery--row .gallery__item--l {
  height: var(--item-height);
}

.gallery--row .caption {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: -50vh;
  left: 0;
  padding: 4.5vw;
  max-width: 730px;
  opacity: 0;
}

.gallery--switch.gallery--row .caption {
  bottom: 0;
  opacity: 1;
}

.gallery--one {
  display: grid;
  place-items: center;
}

.gallery--one .gallery__item {
  width: 400%;
  height: 400%;
  filter: brightness(1) hue-rotate(90deg);
}

.gallery--switch.gallery--one .gallery__item {
  width: 100%;
  height: 100%;
  filter: brightness(0.7) hue-rotate(0deg);
}

.gallery--one .caption {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  margin: 100vh 0 0 -50vw;
  font-size: clamp(2rem, 5vw, 3rem);
  display: grid;
  place-items: center;
}

.gallery--switch.gallery--one .caption {
  margin-top: -50vh;
}

.gallery--grid {
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  row-gap: 2.5vw;
  column-gap: 3vw;
}

.gallery--grid .gallery__item {
  height: 33vh;
  width: 33vw;
}

.gallery--switch.gallery--grid {
  gap: 0;
}

.gallery--switch.gallery--grid .gallery__item {
  height: 110vh;
  width: 110vw;
  filter: brightness(0.65);
}

.gallery--grid .caption {
  position: absolute;
  width: 100vw;
  height: 100vh;
  padding: 0;
  top: 50%;
  left: 50%;
  margin-top: 50vh;
  margin-left: -50vw;
  display: grid;
  place-items: center;
  max-width: none;
  opacity: 0;
}

.gallery--switch.gallery--grid .caption {
  margin-top: -40vh;
  opacity: 1;
}

.gallery--grid .caption p {
  padding: 50vh 30vw 10vh 10vw;
}

.gallery--switch.gallery--grid .caption p {
  margin-top: 0;
}

.gallery--stack {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-content: start;
  gap: 2rem;
  padding: 2rem;
  --offset: 1rem;
}

.gallery--stack .gallery__item {
  border-radius: 1.5vw;
  width: 25vw;
  height: 35vw;
  z-index: 1;
}

.gallery--stack-glass .gallery__item {
  filter: opacity(1);
}

.gallery--switch.gallery--stack .gallery__item {
  grid-area: 1 / 1 / 2 / 2;
}

.gallery--stack .caption {
  position: absolute;
  bottom: 0;
  left: 100vw;
  width: 100vw;
  padding: 5vw;
  color: var(--color-text-alt);
  opacity: 0;
  z-index: 0;
}

.gallery--stack .caption p {
  margin: 0;
}

.gallery--switch.gallery--stack .caption {
  left: 0;
  opacity: 1;
}

.gallery--stack-inverse .gallery__item:nth-child(5) {
  z-index: 2;
}
.gallery--stack-inverse .gallery__item:nth-child(4) {
  z-index: 3;
}
.gallery--stack-inverse .gallery__item:nth-child(3) {
  z-index: 4;
}
.gallery--stack-inverse .gallery__item:nth-child(2) {
  z-index: 5;
}
.gallery--stack-inverse .gallery__item:nth-child(1) {
  z-index: 6;
}

.gallery--switch.gallery--stack .gallery__item:nth-child(2) {
  margin-left: var(--offset);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(3) {
  margin-left: calc(var(--offset) * 2);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(4) {
  margin-left: calc(var(--offset) * 3);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(5) {
  margin-left: calc(var(--offset) * 4);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(6) {
  margin-left: calc(var(--offset) * 5);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(2) {
  filter: brightness(0.8);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(3) {
  filter: brightness(0.7);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(4) {
  filter: brightness(0.6);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(5) {
  filter: brightness(0.5);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(6) {
  filter: brightness(0.4);
}

.gallery--switch.gallery--stack-glass .gallery__item {
  opacity: 0.7;
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(2) {
  transform: scale(0.98);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(3) {
  transform: scale(0.96);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(4) {
  transform: scale(0.94);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(5) {
  transform: scale(0.92);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(6) {
  transform: scale(0.9);
}

.gallery--gridtiny {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  width: 300%;
  padding: 0 2vh;
  height: min-content;
  gap: 1vh;
}

.gallery--switch.gallery--gridtiny {
  width: 100%;
  gap: 2vh;
}

.gallery--gridtiny .gallery__item {
  aspect-ratio: 1;
  width: 100%;
  height: auto;
  filter: contrast(0.8) saturate(0) brightness(0.6) opacity(1);
}

.gallery--switch.gallery--gridtiny .gallery__item {
  filter: contrast(1) saturate(1) brightness(0.8) opacity(0.8);
}

.gallery--gridtiny .caption {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 50%;
  top: 50%;
  margin: -50vh 0 0 -50vw;
  display: grid;
  place-items: center;
  font-size: clamp(2rem, 8vw, 4rem);
  opacity: 0;
}

.gallery--switch.gallery--gridtiny .caption {
  opacity: 1;
}

.gallery--switch .gallery__item--center {
  height: 100vh;
  width: 100vw;
  aspect-ratio: auto;
  filter: brightness(0.5);
}

.gallery--bento {
  display: grid;
  gap: 1vh;
  grid-template-columns: repeat(3, 32.5vw);
  grid-template-rows: repeat(4, 23vh);
  justify-content: center;
  align-content: center;
}

.gallery--switch.gallery--bento {
  grid-template-columns: repeat(3, 100vw);
  grid-template-rows: repeat(4, 49.5vh);
  gap: 15vh;
}

.gallery--bento .gallery__item:nth-child(1) {
  grid-area: 1 / 1 / 3 / 2;
}

.gallery--bento .gallery__item:nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
}

.gallery--bento .gallery__item:nth-child(3) {
  grid-area: 2 / 2 / 4 / 3;
}

.gallery--bento .gallery__item:nth-child(4) {
  grid-area: 1 / 3 / 3 / 3;
}

.gallery--bento .gallery__item:nth-child(5) {
  grid-area: 3 / 1 / 3 / 2;
}

.gallery--bento .gallery__item:nth-child(6) {
  grid-area: 3 / 3 / 5 / 4;
}

.gallery--bento .gallery__item:nth-child(7) {
  grid-area: 4 / 1 / 5 / 2;
}

.gallery--bento .gallery__item:nth-child(8) {
  grid-area: 4 / 2 / 5 / 3;
}

.gallery--bento .caption {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  margin: 100vh 0 0 -50vw;
  font-size: clamp(2rem, 10vw, 5rem);
  display: grid;
  place-items: center;
}

.gallery--switch.gallery--bento .caption {
  margin-top: -50vh;
}

.gallery--grid10 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
}

.gallery--switch.gallery--grid10 {
  grid-template-columns: 1fr 300px 1fr;
  grid-template-rows: 1fr 60vh 1fr;
  grid-gap: 0;
}

.gallery--switch.gallery--grid10 .gallery__item {
  grid-area: 2 / 2 / 3 / 3;
}

.gallery--grid10 .caption {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  margin: 100vh 0 0 -50vw;
  display: grid;
  place-items: center;
  font-size: clamp(2rem, 8vw, 6rem);
}

.gallery--switch.gallery--grid10 .caption {
  opacity: 1;
  margin-top: -50vh;
}

@media screen and (min-width: 53em) {
  .project--intro {
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "label-name name"
      "label-date date"
      "title title"
      "label-mission mission";
  }

  .project--details {
    grid-template-areas: "label-default paragraph";
    grid-template-columns: auto auto;
  }
  .project__label {
    text-align: right;
  }
  .project__mission {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "p1 ..." "... p2";
    column-gap: 2rem;
  }
  .gallery--grid10 {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .gallery--grid10:not(.gallery--switch) .pos-1 {
    grid-area: 1 / 1;
  }
  .gallery--grid10:not(.gallery--switch) .pos-2 {
    grid-area: 1 / 2;
  }
  .gallery--grid10:not(.gallery--switch) .pos-3 {
    grid-area: 1 / 4;
  }
  .gallery--grid10:not(.gallery--switch) .pos-4 {
    grid-area: 1 / 5;
  }
  .gallery--grid10:not(.gallery--switch) .pos-5 {
    grid-area: 3 / 6;
  }
  .gallery--grid10:not(.gallery--switch) .pos-6 {
    grid-area: 1 / 7;
  }
  .gallery--grid10:not(.gallery--switch) .pos-7 {
    grid-area: 1 / 9;
  }
  .gallery--grid10:not(.gallery--switch) .pos-8 {
    grid-area: 3 / 10;
  }
  .gallery--grid10:not(.gallery--switch) .pos-9 {
    grid-area: 2 / 8;
  }
  .gallery--grid10:not(.gallery--switch) .pos-10 {
    grid-area: 4 / 9;
  }
  .gallery--grid10:not(.gallery--switch) .pos-11 {
    grid-area: 3 / 8;
  }
  .gallery--grid10:not(.gallery--switch) .pos-12 {
    grid-area: 2 / 2;
  }
  .gallery--grid10:not(.gallery--switch) .pos-13 {
    grid-area: 3 / 1;
  }
  .gallery--grid10:not(.gallery--switch) .pos-14 {
    grid-area: 3 / 4;
  }
  .gallery--grid10:not(.gallery--switch) .pos-15 {
    grid-area: 4 / 3;
  }
  .gallery--grid10:not(.gallery--switch) .pos-16 {
    grid-area: 4 / 7;
  }

  .gallery--stack .caption {
    bottom: 40%;
    width: 60vw;
    max-width: 800px;
  }

  .gallery--switch.gallery--stack .caption {
    left: 40vw;
  }

  body #cdawrap {
    justify-self: end;
  }
}

.project__subtitle {
  font-size: clamp(1rem, 8vw, 5rem);
  font-weight: 400;
  margin: 6vh 0;
  line-height: 1;
}

.project__mission--white p {
  color: var(--color-text-white);
}

/**
   * On-Scroll SVG Filter Effect
   * Combining GSAP's Scroll Trigger and Flip with a SVG Filter,
   * based on a demo by Fabio Ottaviani.
   */

.intro {
  height: calc(100vh - 3rem);
  text-align: center;
  place-items: center;
  display: grid;
  margin-bottom: 30vh;
  background: linear-gradient(0deg, transparent, var(--color-bg-alt));
}

.intro__title {
  place-items: center;
  margin: 0;
  line-height: 0.9;
  display: grid;
  margin-top: 15vh;
  font-weight: 400;
}

.intro__title-pre {
  /* font-family: "stinger-variable", sans-serif; */
  font-variation-settings: "wdth" 140, "wght" 300;
  font-weight: 300;
  font-size: clamp(2rem, 10vw, 5rem);
  color: var(--color-title);
}

.intro__title-sub {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.intro__info {
  max-width: 15ch;
  opacity: 0.6;
  margin-bottom: 4rem;
  padding-bottom: 1rem;
  line-height: 1.2;
  position: relative;
  align-self: end;
}

.intro__info::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 50%;
}

.content__title {
  margin-top: 40vh;
}

.credits {
  font-size: 1.5rem;
  text-align: center;
  margin: 50vh auto 0;
}

.card-wrap {
  margin-top: 5vh;
  display: grid;
  grid-gap: 2rem;
  grid-auto-flow: row;
  grid-template-columns: 250px;
}

.card__image {
  display: block;
  border-radius: 7px;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: auto;
  aspect-ratio: 4 / 3;
  filter: brightness(0.8);
}

.content-wrap {
  display: grid;
  place-items: center;
  grid-template-areas: "main";
}

.content {
  grid-area: main;
  display: grid;
  place-items: center;
  line-height: 1.2;
  grid-template-areas: "content";
}
/* 
   .content-wrap .content:first-child {
     height: 100vh;
   } */

.content--layout {
  grid-template-areas:
    "title-up title-down"
    "img img"
    "text text";
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.content__img {
  grid-area: img;
  max-width: 50%;
  height: auto;
}

.content__img--1 {
  aspect-ratio: 896/1344;
}

.content__img--2 {
  aspect-ratio: 1000/450;
}

.content__img--3 {
  aspect-ratio: 1000/560;
}

.content__img--4 {
  aspect-ratio: 1400/560;
}

.content__img--5 {
  aspect-ratio: 680/920;
}

.content__img--6 {
  aspect-ratio: 1;
}

.content__img--7 {
  aspect-ratio: 1400/560;
}

.title-wrap {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
}

.title {
  padding-top: 0.3em;
  line-height: 0.725;
  font-size: 2rem;
  font-variation-settings: "wdth" 120, "wght" 150;
  font-weight: 300;
  position: relative;
  z-index: 10;
  text-indent: -0.1em;
}

.title--up {
  grid-area: title-up;
  font-family: "stinger-variable", sans-serif;
  font-style: italic;
}

.title--down {
  grid-area: title-down;
  font-variation-settings: "wdth" 120, "wght" 350;
}

.content__text {
  grid-area: text;
  text-transform: uppercase;
  margin: 0;
  opacity: 0.5;
}

@media screen and (min-width: 53em) {
  .title {
    font-size: clamp(2rem, 12vw, 7rem);
  }

  /* .content-wrap:not(:last-child) {
       margin-bottom: 30vmax;
     } */

  .content__img {
    max-width: none;
  }

  .content__img--1 {
    height: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
  }

  .content__img--2 {
    width: 60vw;
  }

  .content__img--3 {
    width: 30vw;
    align-self: center;
  }

  .content__img--4 {
    width: 100%;
    align-self: center;
  }

  .content__img--5 {
    height: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
  }

  .content__img--6 {
    max-width: 100%;
  }

  .content__img--7 {
    width: 100%;
    align-self: center;
  }

  .content--layout-1 {
    grid-template-areas:
      "title-up img ..."
      "text img title-down";
    grid-template-columns: 30% auto 30%;
    grid-template-rows: 1fr 1fr;
    column-gap: 2vw;
  }

  .content--layout-2 {
    grid-template-areas:
      "title-up ..."
      "img img"
      "text title-down";
    grid-template-columns: auto auto;
    justify-content: center;
    row-gap: 3vh;
  }

  .content--layout-3 {
    grid-template-areas:
      "title-up img title-down"
      "text text text";
    grid-template-columns: 20vw auto 20vw;
    grid-template-rows: auto auto;
    row-gap: 10vh;
    column-gap: 2vw;
    justify-content: center;
    align-content: center;
  }

  .content--layout-4 {
    width: 100%;
    grid-template-areas:
      "title-up"
      "img"
      "title-down"
      "text";
    grid-template-columns: 1fr;
    justify-content: center;
    row-gap: 4vh;
  }

  .content--layout-5 {
    grid-template-areas:
      "title-up img ..."
      "text img title-down";
    grid-template-columns: 30% auto 30%;
    grid-template-rows: 1fr 1fr;
    column-gap: 3vw;
  }

  .content--layout-6 {
    grid-template-areas:
      "title-up img"
      "title-down img"
      "text img";
    grid-template-columns: 1fr 50%;
    grid-template-rows: auto auto 1fr;
    column-gap: 3vw;
    row-gap: 0;
  }

  .content--layout-7 {
    width: 100%;
    grid-template-areas:
      "img img img img"
      "... text title-up ..."
      "... text title-down ...";
    grid-template-columns: 10vw 1fr 1fr 10vw;
    justify-content: center;
    row-gap: 4vh;
  }

  .title--up {
    justify-self: end;
    align-self: start;
  }

  .content--layout-2 .title--up {
    justify-self: start;
  }

  .content--layout-3 .title--up {
    justify-self: end;
    align-self: center;
  }

  .content--layout-4 .title--up {
    justify-self: center;
  }

  .content--layout-6 .title--up {
    justify-self: end;
    align-self: start;
  }

  .content--layout-7 .title--up {
    justify-self: start;
  }

  .title--down {
    justify-self: start;
    align-self: end;
  }

  .content--layout-2 .title--down {
    justify-self: end;
    align-self: start;
    margin-top: -0.1em;
  }

  .content--layout-3 .title--down {
    align-self: center;
  }

  .content--layout-4 .title--down {
    justify-self: center;
  }

  .content--layout-6 .title--down {
    justify-self: end;
    align-self: start;
  }

  .content--layout-2 .content__text {
    align-self: start;
    justify-self: start;
    max-width: 400px;
  }

  .content--layout-1 .content__text {
    max-width: 250px;
    text-align: right;
    justify-self: end;
    align-self: end;
  }

  .content--layout-3 .content__text {
    column-count: 2;
    column-gap: 4vw;
    max-width: 590px;
    text-align: justify;
  }

  .content--layout-4 .content__text {
    text-align: center;
    max-width: 400px;
    margin-top: 3rem;
  }

  .content--layout-5 .content__text {
    max-width: 250px;
    text-align: right;
    justify-self: end;
    align-self: end;
  }

  .content--layout-6 .content__text {
    max-width: 250px;
    justify-self: end;
    align-self: end;
    text-align: right;
  }

  .content--layout-7 .content__text {
    max-width: 250px;
    justify-self: start;
    align-self: start;
    text-align: right;
  }

  .card-wrap {
    grid-template-columns: repeat(3, 250px);
  }

  body #cdawrap {
    justify-self: end;
  }
}

.project__title.hero {
  grid-area: title;
  font-size: clamp(2rem, 13vw, 8rem);
  font-weight: 400;
  margin: 10vh 0;
  line-height: 1;
}

@media screen and (min-width: 53em) {
  .title.hero {
    font-size: clamp(2rem, 15vw, 9rem);
  }
}

/**
   * On-Scroll 3D Grid Animations
   * On-scroll animations for perspective
   * image grids with different layouts.
   */

:root {
  font-size: 16px;
  --color-text: #000;
  --color-bg: #000;
  --color-link: #fff;
  --color-link-hover: #907030;
  --color-title: #907030;
  --perspective: 1500px;
  --grid-item-ratio: 1.5;
  --grid-width: 100%;
  --grid-height: auto;
  --grid-gap: 2vw;
  --grid-columns: 4;
  --grid-inner-scale: 1;
}

.content {
  position: relative;
  margin-bottom: 20vh;
}

.content--spacing {
  margin-bottom: 100vh;
}

.content__title {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 50%;
  left: 50%;
  margin: -50vh 0 0 -50vw;
  padding: 0 10vw;
  display: grid;
  place-items: center;
  text-align: center;
  font-weight: 300;
  font-size: clamp(1.5rem, 15vw, 6.5rem);
}

.content__title--top {
  align-items: start;
}

.content__title--bottom {
  align-items: end;
}

.content__title--left {
  justify-items: start;
  text-align: left;
}

.content__title--right {
  justify-items: end;
  text-align: right;
}

.outro {
  display: grid;
  place-items: center;
  margin: 40vh 0;
}

.cd-grid {
  display: grid;
  place-items: center;
  padding: 2rem;
  width: 100%;
  perspective: var(--perspective);
}

.cd-grid-wrap {
  height: var(--grid-height);
  width: var(--grid-width);
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  gap: var(--grid-gap);
  transform-style: preserve-3d;
}

.cd-grid__item {
  aspect-ratio: var(--grid-item-ratio);
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  display: grid;
  place-items: center;
}

.cd-grid__item-inner {
  position: relative;
  width: calc(1 / var(--grid-inner-scale) * 100%);
  height: calc(1 / var(--grid-inner-scale) * 100%);
  background-size: cover;
  background-position: 50% 50%;
}

@media screen and (min-width: 53em) {
  .frame {
    grid-template-columns: auto auto auto 1fr;
    grid-template-areas: "title prev ... sponsor";
    align-content: space-between;
    justify-items: start;
    grid-gap: 2rem;
  }
  .frame__demos {
    justify-self: end;
  }

  .content--outro {
    height: 100vh;
    justify-content: center;
  }
}

.transparent {
  background-color: #f8fafc;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%2394a3b8' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.transparent--dark {
  background-color: #1e293b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%2394a3b8' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
}

/* --------------------------------

File#: _1_circular-progress-bar
Title: Circular Progress Bar
Descr: Display the current progress of a task using a circular SVG shape
Usage: codyhouse.co/license

-------------------------------- */
:root {
  --c-progress-bar-size: 24px;
  --c-progress-bar-stroke-width: 12;
}

.c-progress-bar {
  width: var(--c-progress-bar-size);
}

.c-progress-bar__shape {
  width: 100%;
  position: relative;
}
.c-progress-bar__shape svg {
  display: block;
  width: var(--c-progress-bar-size);
  height: var(--c-progress-bar-size);
  stroke-width: var(--c-progress-bar-stroke-width);
}

.c-progress-bar__bg {
  stroke: currentColor;
  opacity: 0.125;
}

.c-progress-bar__fill {
  stroke: currentColor;
}

.c-progress-bar__value {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.c-progress-bar__value i {
  font-size: 0.65em;
}

.c-progress-bar:not(.c-progress-bar--init) .c-progress-bar__fill,
.c-progress-bar:not(.c-progress-bar--init) .c-progress-bar__value {
  visibility: hidden;
}

.c-progress-bar--color-update {
  --c-progress-bar-color-1: 30;
  --c-progress-bar-color-2: 65;
  --c-progress-bar-color-3: 100;
}
.c-progress-bar--color-update.c-progress-bar--init .c-progress-bar__fill {
  transition: stroke 0.2s;
}

.c-progress-bar--fill-color-1 .c-progress-bar__fill {
  @apply stroke-red-400;
}

.c-progress-bar--fill-color-2 .c-progress-bar__fill {
  @apply stroke-amber-400;
}

.c-progress-bar--fill-color-3 .c-progress-bar__fill {
  @apply stroke-teal-400;
}

.\[mask-image\:linear-gradient\(0deg\2c transparent\2c black\)\] {
  -webkit-mask-image: linear-gradient(0deg, #0000, #000);
  mask-image: linear-gradient(0deg, #0000, #000);
}

/* Stacked card slider JS */

.cards-box {
  position: relative;
  transform: translateX(-15px);
}
.cards-box .card {
  width: calc(18rem + 19vh);
  max-width: 80vw;
  background: #ffffff;
  border-radius: 14px;
  cursor: pointer;
}
.cards-box .card.hide {
  visibility: hidden;
}
.cards-box .card:not(.hide) {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.8s cubic-bezier(0.18, 0.98, 0.45, 1);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
}
.cards-box .card:not(.hide)[data-slide="0"] {
  transform: translate(0px, 0px) scale(1);
  z-index: 6;
  opacity: 1;
}
.cards-box .card:not(.hide)[data-slide="1"] {
  transform: translate(15px, 15px) scale(0.975);
  z-index: 5;
  opacity: 0.9;
}
.cards-box .card:not(.hide)[data-slide="2"] {
  transform: translate(30px, 30px) scale(0.95);
  z-index: 4;
  opacity: 0.8;
}
.cards-box .card:not(.hide)[data-slide="3"] {
  transform: translate(45px, 45px) scale(0.925);
  z-index: 3;
  opacity: 0.7;
}
.cards-box .card:not(.hide)[data-slide="4"] {
  transform: translate(60px, 60px) scale(0.9);
  z-index: 2;
  opacity: 0.6;
}
.cards-box .card:not(.hide)[data-slide="5"] {
  transform: translate(75px, 75px) scale(0.875);
  z-index: 1;
  opacity: 0.5;
}
.cards-box .card:not(.hide)[data-slide="0"] {
  transition: all 0.32s cubic-bezier(0.18, 0.98, 0.45, 1);
}

.content-placeholder {
  padding: 14px 18px;
}

/* CTA button */

.cta {
  --content-size: 200px;
  z-index: 1;
  display: inline-block;
  width: calc(var(--content-size) * 1.5);
  display: grid;
  place-items: center;
}

.cta__content {
  position: relative;
}

/* Word */
.cta span:nth-of-type(1) {
  opacity: var(--scale, 0);
  transition: opacity 0.2s 0.6s;
  white-space: nowrap;
  padding: 0 0 0 0.5rem;
}

/* The Bloom */
.cta__content::before {
  --back: linear(
    0,
    0.3412 4.06%,
    0.6411 8.2%,
    0.9005 12.43%,
    1.1205 16.77%,
    1.3013 21.22%,
    1.4438 25.8%,
    1.549 30.54%,
    1.5878 32.98%,
    1.6175 35.47%,
    1.6457 39.41%,
    1.6518 41.44%,
    1.6528 43.52%,
    1.6391 47.83%,
    1.6042 52.44%,
    1.5562 56.74%,
    1.4899 61.5%,
    1.202 78.86%,
    1.0988 85.97%,
    1.0551 89.79%,
    1.0244 93.36%,
    1.0061 96.75%,
    1
  );
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100%;
  z-index: -1;
}

/* The icon holder */
.cta span:nth-of-type(2) {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
}

.cta svg {
  width: 20px;
  fill: hsl(0 0% 100% / 0.75);
}

.cta a:is(:hover, :focus-visible) svg {
  fill: #ffffff;
}

.cta a {
  --elastic: linear(0, 0.4789 7.06%, 0.7612 12.38%, 0.8693 15.13%, 0.9538 17.95%, 1.0165 20.89%, 1.059 24.04%, 1.0829 27.58%, 1.0884 31.64%, 1.0769 36.44%, 1.0202 50.6%, 1.0005 59.62%, 0.9945 70.6%, 1);
  text-decoration-line: none;
  overflow: hidden;
  padding: 0.5rem;
  display: grid;
  align-items: center;
  letter-spacing: -0.01em;
  grid-template-columns: 0 40px;
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
}

/* Deactivation animation */
.cta--deactivated .cta__content::before {
  scale: 0;
  opacity: 0;
  transition: scale 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.cta.cta--deactivated a {
  scale: 0;
  transition: scale 0.2s 0.7s, grid-template-columns 0.5s 0.2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  grid-template-columns: 0 40px;
}

.cta--deactivated .cta__content span:first-of-type {
  opacity: 0;
  transition: opacity 0.2s 0s;
}

.cta--deactivated .cta__content span:last-of-type {
  scale: 0;
  opacity: 0;
  transition: scale 0.2s, opacity 0.2s;
}

@supports (transition-timing-function: linear(0, 1)) {
  /* Safari doesn't support linear timing function, so we use cubic-bezier there */
  .cta--deactivated .cta__content::before {
    transition: scale 0.5s var(--back);
  }

  .cta.cta--deactivated a {
    transition: scale 0.2s 0.7s, grid-template-columns 0.5s 0.2s var(--elastic);
  }
}

/* Activation animation */
.cta--activated .cta__content::before {
  scale: 0.99;
  opacity: 1;
  transition: scale 0.5s cubic-bezier(0.17, 1.8, 0.48, 1.82);
}

.cta.cta--activated a {
  scale: 1;
  transition: scale 0.2s 0s, grid-template-columns 0.5s 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  grid-template-columns: var(--content-size, auto) 40px;
}

.cta--activated .cta__content span:first-of-type {
  opacity: 1;
  transition: opacity 0.2s 0.7s;
}

.cta--activated .cta__content span:last-of-type {
  scale: 1;
  opacity: 1;
  transition: scale 0.2s 0.4s, opacity 0.2s 0.4s;
}

@supports (transition-timing-function: linear(0, 1)) {
  /* Safari doesn't support linear timing function, so we use cubic-bezier there  */

  .cta--activated .cta__content::before {
    transition: scale 0.5s var(--back);
  }

  .cta.cta--activated a {
    transition: scale 0.2s 0s, grid-template-columns 0.5s 0.6s var(--elastic);
  }
}

/* Player controls */

.koosh__play-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  pointer-events: all;
  z-index: 999;
}

/* Radial Play button */

.radial_playButton {
  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0;
}

.progress-circle {
  transform: rotate(-90deg);
  margin-top: 0;
}

.progress-circle-back {
  fill: none;
  stroke: rgba(255, 255, 255, 0.4);
  stroke-width: 3px;
}

.progress-circle-prog {
  fill: none;
  stroke: white;
  stroke-width: 2px;
  /* stroke-dasharray: 0 999;     */
  stroke-dashoffset: 0px;
  transition: stroke-dasharray 0.2s linear 0s;
}

@keyframes loading {
  from {
    stroke-dasharray: 20 50;
    stroke-dashoffset: 0%;
  }

  to {
    stroke-dasharray: 20 50;
    stroke-dashoffset: -140%;
  }
}

.progress-circle-prog_loading {
  animation: loading 1s linear 0s infinite;
}

.button-icon {
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner_V8m1 {
  transform-origin: center;
  animation: spinner_zKoa 2s linear infinite;
}
.spinner_V8m1 circle {
  stroke-linecap: round;
  animation: spinner_YpZS 1.5s ease-in-out infinite;
}
@keyframes spinner_zKoa {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner_YpZS {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
